import React from "react";
import "./Footer.css";
import styled from "styled-components";

import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

import muiStyle from '@mui/material/styles/styled';
import {useHistory} from "react-router-dom";

const MyPhoneIcon = muiStyle(PhoneIcon)({
    color: 'green',
    margin: "8px 8px 8px 0",
    fontSize: "18px",
});

const MyEmailIcon = muiStyle(EmailIcon)({
    color: 'green',
    margin: "8px 8px 8px 0",
    fontSize: "18px",
});

const FooterNavItems = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 2;

  > li {
    list-style: none;
    text-align: center;
  }

  > li > button, > li > a {
    display: flex;
    color: #2E8764;

    text-decoration: none;
    text-transform: uppercase;
    background: none;

    text-align: right;

    font-weight: bold;
    font-size: 14px;
    letter-spacing: 3px;
  }
`

export default function Footer() {

    const history = useHistory();

    // function scrollToTop() {
    //     window.scrollTo(0, 0);
    // }


    return <div>
        <div className={"footer-main-container"}>
            <div className={"footer-info-container"}>

                <FooterNavItems>
                    {/*<li>*/}
                    {/*    <button onClick={scrollToTop}>Top*/}
                    {/*    </button>*/}
                    {/*</li>*/}

                    <li>
                        <button onClick={() => {
                            history.push("/")
                        }}>Home
                        </button>
                    </li>

                    <li>
                        <button onClick={() => {
                            history.push("/aboutus")
                        }}>About Us
                        </button>
                    </li>
                    <li>
                        <button onClick={() => {
                            history.push("/contactus")
                        }}>Contact Us
                        </button>
                    </li>
                </FooterNavItems>

                <p>
                    Registered Office
                </p>
                <p>
                    119/3, Barod Road, Agar Malwa, Madhya Pradesh, 465441, India
                </p>
                <a href={"mailto:info@zirconadvance.com"}><MyEmailIcon/> info@zirconadvance.com</a>
                <a href={"tel:+916264565964"}><MyPhoneIcon/>+916264565964</a>
            </div>
            <img className={"footer-logo"} src={process.env.PUBLIC_URL + '/android-chrome-192x192.png'}
                 alt={"Zircon Logo"}/>
        </div>

 {/*<div className={"footer-designer-container"}>*/}
            {/* <p>Designed and Developed by: Samyak Jain</p>*/}
             {/*<a href={"mailto:jainsamyak2300@gmail.com"}>jainsamyak2300@gmail.com</a>*/}
        {/* </div>*/}
    </div>
}
